import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, IconButton, Paper, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

export const HelpDialog = ({ open, handleClose, baseUrl, location }) => {

    const { t } = useTranslation();

    const [src, setSrc] = useState(null);

    let elements = location.pathname.split('/');
    let page = elements[elements.length - 1];
    let url = baseUrl + '/' + page;
    axios.get(`/webstore/service/v1/admin/help/test?page=${page}&baseUrl=${encodeURI(baseUrl)}`).
        then((res => {
            setSrc(url);
        })).catch(error => {
            setSrc(baseUrl);
        });

    return (
        src && <Dialog fullScreen open={open} handleClose={handleClose}>
            <Stack direction="row" alignItems="center" sx={{ margin: '1em', justifyContent: "space-between" }}>
                <Typography variant="dialogTitle">{t('common.help')}</Typography>
                <IconButton onClick={handleClose}><CloseIcon /></IconButton>
            </Stack>
            <Paper sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                <iframe
                    style={{
                        backgroundColor: 'white',
                        height: "100vh",
                        left: 0,
                        top: 0,
                        width: "100vw",
                    }}
                    src={src}></iframe>
            </Paper>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={5}
                sx={{ margin: '1em' }}
            >
                <Button sx={{ textTransform: 'none' }} variant="navigationBack" onClick={handleClose}>OK</Button>
            </Stack>
        </Dialog >
    );
}
